<template>
  <div>
    <div v-if="featuresLoading">
      <div class="text-center px-10 py-10">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <v-card class="ti-widget overview-lease-widget-card-wrapper" :class="{'hb-border-warning' : leaseWithTenants.balance > 0 && is_closed, 'hb-elevation-medium' : elevation !== false}" v-if="lease.id && leaseWithTenants.id" :elevation="elevation === false ? 0 : null">
        <div class="v-card__header ti-widget__header mr-0" :class="[computedHeaderBackgroundColor]">
          <v-card-title class="ti-widget__title mr-0 pr-0 pb-0">
            <v-row class="ma-0 pa-0">
              <v-col :cols="parent !== 'space-profile' ? 10 : 12" class="ma-0 pa-0">
                <!-- BCT: Tenant Details in header for Space profile HB-909 -->
                <mini-profile-view 
                  v-if="contact && parent === 'space-profile'"
                  :contact-id="contact.id"
                  :use-contact="false"
                  :contact-data="contact"
                />
                <HbHeaderInfo
                  v-else-if="unit && unit.type"
                  :type="unit.type"
                  :title="unit.number ? unit.number : undefined"
                  :has-insurance="insuranceIsCurrent"
                  :insurance-name="insurance && insurance.name ? insurance.name : undefined"
                  :insurance-description="insurance && insurance.description ? insurance.description : undefined"
                  :status="leaseWithTenants && leaseWithTenants.Standing && leaseWithTenants.Standing.name ? leaseWithTenants.Standing.name : undefined"
                  :category-one="unit.label ? unit.label : undefined"
                  :category-two="unit.Category && unit.Category.name ? unit.Category.name : undefined"
                  :property="computedPropertyInfo"
                  :access-code="!is_closed && isSpaceAccessCodesVendor ? lease.Unit.pin : undefined"
                  :access-error="computedAccessHasError"
                  @pin="viewContact('access')"
                  row-max-width="350px"
                  @emitType="emitType"
                />
              </v-col>
              <v-col :cols="parent !== 'space-profile' ? 2 : 0" class="mx-0 mb-0 py-0 px-2 mt-n2">
                <hb-menu
                  v-if="(parent !== 'space-profile') && !is_closed && (leaseWithTenants && leaseWithTenants.Standing && leaseWithTenants.Standing.name && leaseWithTenants.Standing.name !== 'Pending')"
                  options
                  :optionsHoverType="emittedType"
                  align-right
                >
                  <v-list>
                    <v-list-item v-if="!isAuctionPaymentOrMovedOut && !nationalAccount" :disabled="isLoading($options.name)" @click="invoiceModal = true">
                        Create Invoice
                    </v-list-item>
                    <hb-tooltip v-else-if="nationalAccount">
                      <template v-slot:item>
                          <v-list-item disabled>
                            Create Invoice
                          </v-list-item>
                      </template>
                      <template v-slot:body>
                          Cannot create Invoice for space leased by National Account. Please contact the National Account Manager for more details.
                      </template>
                    </hb-tooltip>                    
                    <hb-tooltip v-else>
                      <template v-slot:item>
                          <v-list-item disabled>
                            Create Invoice
                          </v-list-item>
                      </template>
                      <template v-slot:body>
                          This space has been auctioned
                      </template>
                    </hb-tooltip>
                    <v-list-item :disabled="isLoading($options.name)" @click="$emit('transfer', lease)" v-if="!isAuctionPaymentOrMovedOut && leaseWithTenants && leaseWithTenants.balance <= 0 && !openInvoices && checkPermissionProperties({label: 'transfer_lease', properties: [lease.Unit.property_id]})">
                      Transfer
                    </v-list-item>
                    <hb-tooltip v-else>
                      <template v-slot:item>
                          <v-list-item disabled>
                            Transfer
                          </v-list-item>
                      </template>
                      <template v-slot:body>
                          <div v-if="isAuctionPaymentOrMovedOut">
                            This space has been auctioned
                          </div>
                          <div v-else-if="!checkPermissionProperties({label: 'transfer_lease', properties: [lease.Unit.property_id]})">
                            User is not allowed to perform transfer on this property
                          </div>
                          <div v-else>
                            Clear out balance to transfer unit
                          </div>
                      </template>
                    </hb-tooltip>
                    <v-list-item :disabled="isLoading($options.name)" @click="$emit('moveOut', lease)" v-if="checkPermissionProperties({label: 'move_out_lease', properties: [lease.Unit.property_id]})">
                      Move Out
                    </v-list-item>
                    <hb-tooltip v-else>
                      <template v-slot:item>
                        <v-list-item disabled>
                          Move Out
                        </v-list-item>
                      </template>
                      <template v-slot:body>
                        <div>
                          User is not allowed to perform move out on this property
                        </div>
                      </template>
                    </hb-tooltip>
                    <v-list-item v-if="!isAuctionPaymentOrMovedOut" :disabled="isLoading($options.name)" @click="$emit('merchandise', lease)">
                      Add Fee / Merchandise
                    </v-list-item>
                    <hb-tooltip v-else>
                      <template v-slot:item>
                          <v-list-item disabled>
                            Add Fee / Merchandise
                          </v-list-item>
                      </template>
                      <template v-slot:body>
                          This space has been auctioned
                      </template>
                    </hb-tooltip>
                  </v-list>
                </hb-menu>

                <hb-menu
                  v-if="(parent !== 'space-profile') && is_closed && (leaseWithTenants && leaseWithTenants.Standing && leaseWithTenants.Standing.name && leaseWithTenants.Standing.name !== 'Pending')"
                  options
                  :optionsHoverType="emittedType"
                  align-right
                >
                  <v-list>
                    <v-list-item :disabled="isLoading($options.name)" v-if="leaseWithTenants && leaseWithTenants.balance > 0 && leaseWithTenants.OpenInvoices" @click="goPayment">
                      Take a Payment
                    </v-list-item>
                    <v-list-item v-else disabled>
                      Take a Payment
                    </v-list-item>                    
                    <v-list-item :disabled="isLoading($options.name)" v-if="leaseWithTenants && leaseWithTenants.balance > 0 && leaseWithTenants.OpenInvoices" @click="writeOffModal = true">
                      Write-Off Balance
                    </v-list-item>
                    <v-list-item v-else disabled>
                      Write-Off Balance
                    </v-list-item>
                  </v-list>
                </hb-menu>
              </v-col>
            </v-row>
          </v-card-title>
        </div>
        <div v-if="is_loading" class="v-card__content ti-widget__content">
          <v-card class="ti-widget" style="min-height:700px">
            <v-row justify="center" align="center">
              <v-col class="text-center ma-12">
                <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-else class="v-card__content ti-widget__content">
          <v-row
            v-if="isSpaceAccessCodesVendor && !is_closed && (lease.Unit.soft_catch === 1 || lease.Unit.late_catch === 1 || lease.Unit.hard_catch === 1)"
            class="accessRestrictionsWrapper px-4 py-3"
            no-gutters
          >
            <v-col cols="5" class="font-weight-medium">
              Access Restrictions
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="7">
              <v-row v-if="lease.Unit.soft_catch" no-gutters justify="end" style="padding: 2px 0 4px;">
                <hb-status-general status="Access Alert"></hb-status-general>
              </v-row>
              <v-row v-if="lease.Unit.late_catch || lease.Unit.hard_catch" no-gutters justify="end" style="padding: 2px 0 4px;">
                <hb-status-general v-if="lease.Unit.hard_catch" status="Manual Suspension"></hb-status-general>
                <hb-status-general v-else-if="lease.Unit.late_catch" status="Delinquency Suspension"></hb-status-general>
              </v-row>
            </v-col>
          </v-row>
          <WriteOff
            v-model="writeOffModal"
            :writeOffModal="writeOffModal"
            :lease="leaseWithTenants"
            :unit="leaseWithTenants.Unit"
            :contact="contact"
          >
          </WriteOff>
          <!-- // BCT: Component separated from OverviewLeaseWidget -->
          <OverviewLeaseDetails
            :unit="unit"
            :lease="lease"
            :leaseWithTenants="leaseWithTenants"
            :invoice="invoice"
            :offset="offset"
            :services="services"
            :credits="credits"
            :contact="contact"
            :nationalAccount="nationalAccount"
            @nextPeriod="nextPeriod"
            @prevPeriod="prevPeriod"
            @insuranceDialogClose="insuranceDialogClose"
            @auctionSale="$emit('auctionSale', lease)"
            @processPayment="$emit('processPayment', lease.LeaseAuction.payment_id)"
            @refundAuction="$emit('refundAuction', lease.LeaseAuction)"
            >
          </OverviewLeaseDetails>

          <div  v-if="parent === 'space-profile'">
            <v-divider></v-divider>
            <OverviewLeaseSettings
              :unit="unit"
              :lease="lease"
              :leaseWithTenants="leaseWithTenants"
              :invoice="invoice"
              :services="services"
              :contact="contact"
              :nationalAccount="nationalAccount"
              :payment_methods="payment_methods"
              :vehicleData="selectedVehicle"
              :autoPayData="autoPaymentData"
              :promotions="promotionsData"
              @fetchLease="fetchLease"
              >
            </OverviewLeaseSettings>
          </div>
          <!-- BCT: Component separated from OverviewLeaseWidget // -->

          <v-expansion-panels v-else class="hb-expansion-panel" v-model="more_settings" multiple flat>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header class="hb-link px-4 hb-lease-dropdown-header" expand-icon="mdi-arrow-collapse-vertical">
                <span class="hb-font-body-medium hb-text-night"> <HbIcon mdi-code="mdi-menu" /> {{is_closed ? 'More Information' : 'Lease Information'}}</span>
              </v-expansion-panel-header>
              <v-divider v-if="more_settings.includes(0)"></v-divider>
              <v-expansion-panel-content class="pa-0 ma-0">
              <OverviewLeaseSettings
                :unit="unit"
                :lease="lease"
                :leaseWithTenants="leaseWithTenants"
                :invoice="invoice"
                :services="services"
                :contact="contact"
                :payment_methods="payment_methods"
                :vehicleData="selectedVehicle"
                :autoPayData="autoPaymentData"
                :promotions="promotionsData"
                :nationalAccount="nationalAccount"
                @fetchLease="fetchLease"
                >
              </OverviewLeaseSettings>
                <div class="d-flex justify-end my-3 mr-3" v-if="(parent !== 'space-profile') && !is_closed && (leaseWithTenants && leaseWithTenants.Standing && leaseWithTenants.Standing.name && leaseWithTenants.Standing.name !== 'Pending')">

                  <!-- put create invoice button in later -->
                  <hb-btn v-if="!isAuctionPaymentOrMovedOut && !nationalAccount" color="secondary" small :disabled="isLoading($options.name)" @click="invoiceModal = true" class="mr-3">Create Invoice</hb-btn>
                  <div v-if="!isAuctionPaymentOrMovedOut">
                    <hb-btn class="mr-3" color="secondary" small :disabled="isLoading($options.name)" @click="$emit('transfer', lease)" v-if="leaseWithTenants && leaseWithTenants.balance <= 0 && !openInvoices && checkPermissionProperties({label: 'transfer_lease', properties: [lease.Unit.property_id]})">Transfer</hb-btn>
                    <v-tooltip bottom v-else-if="!checkPermissionProperties({label: 'transfer_lease', properties: [lease.Unit.property_id]})">
                      <template v-slot:activator="{ on }">
                                          <span v-on="on">
                                              <hb-btn class="mr-3" small color="secondary" disabled style="cursor: pointer;">Transfer</hb-btn>
                                          </span>
                      </template>
                      <span>User is not allowed to perform transfer on this property</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on }">
                                          <span v-on="on">
                                              <hb-btn class="mr-3" small color="secondary" disabled style="cursor: pointer;">Transfer</hb-btn>
                                          </span>
                      </template>
                      <span>Clear out balance to transfer unit</span>
                    </v-tooltip>
                  </div>
                  <v-tooltip bottom v-if="!checkPermissionProperties({label: 'move_out_lease', properties: [lease.Unit.property_id]})">
                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <hb-btn class="mr-3" small color="secondary" disabled style="cursor: pointer;">Move Out</hb-btn>
                                        </span>
                    </template>
                    <span>User is not allowed to perform move out on this property</span>
                  </v-tooltip>
                  <hb-btn color="secondary" small :disabled="isLoading($options.name)" @click="$emit('moveOut', lease)" v-else>Move Out</hb-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </div>
        <CreateInvoiceModal 
          v-model="invoiceModal" 
          v-if="invoiceModal" 
          :contact="contact" 
          :lease="lease" 
          :property_id="lease.Unit.property_id" 
          @close="invoiceModal = false"
        ></CreateInvoiceModal>

        <modal v-if="showEditBreakdownModal" size="lg" allow-click-away="true" @close="showEditBreakdownModal = false" >
          <h3 slot="header">Setup Auto Pay</h3>
          <edit-breakdown
            :paymentMethods="payment_methods"
            :rent="lease.rent"
            :lease_id="lease.id"
            slot="body"
            @close="showEditBreakdownModal = false"
            @refetch="fetchLeasePaymentMethods" 
          >
        
          </edit-breakdown>
        </modal>
      </v-card>
    </div>
    <hb-modal
      title="Update Billing Date"
      size="medium"
      confirmation
      v-model="show_update_billing_modal"
      v-if="show_update_billing_modal"
      @close="cancelInvoice"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">This space now has a balance due. If you skip the payment an invoice will be automatically generated and sent to the tenant.</div>
        <div class="px-6 py-4">Would you like to take a payment now? </div>
      </template>2
      <template v-slot:actions>
        <hb-btn color="secondary" class="pr-4" v-if="hasPermission('bill_day_change_skip_payment')"  @click="billingOnPayment(true)">Skip Payment</hb-btn>
          <hb-tooltip v-else>
              <template v-slot:item >
                  <hb-btn color="secondary" class="pr-4" disabled>Skip Payment</hb-btn>
              </template>
              <template v-slot:body> You do not have the permission to skip payment </template>
          </hb-tooltip>
        <hb-btn color="primary" @click="showTakePaymentModal">Take a Payment</hb-btn>
      </template>
    </hb-modal>
    <BillingOnTakePaymentModal
      v-model="show_take_payment_modal"
      v-if="show_take_payment_modal"
      :contact="contact"
      :lease="lease"
      @close="resetBillingOnField"
    >
    </BillingOnTakePaymentModal>
  </div>

</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import moment from 'moment';
    import EditServiceDialog from './EditServiceDialog.vue';
    import EditBreakdown from './EditBreakdown.vue';
    import { EventBus } from '../../EventBus.js';
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import CreateInvoiceModal from './CreateInvoiceModal.vue';
    import EditPayment from '../payments/EditPayment.vue';
    import BillingOnTakePaymentModal from './billingOn/BillingOnTakePaymentModal.vue';
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import OverviewLeaseDetails from './OverviewLeaseDetails.vue'
    import VEHICLE from '@/constants/vehicle.js'; 
    import OverviewLeaseSettings from './OverviewLeaseSettings.vue'
    import WriteOff from '../includes/WriteOff/Index.vue'
    import { calendarMixin } from '../../mixins/calendarMixin';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import MiniProfileView from '../includes/LeadIntake/MiniProfileView.vue';
    export default {
        name: "OverviewLeaseWidget",
        emits: ['takeAction',],
        data() {
            return {
              featuresLoading: true,
              invoiceModal: false,
              addPaymentMethod: false,
              is_auto_pay_after_billing_date: false,
              activelyEditing: '',
              paid_through_date: null,
              prepaid_balance: 0,
              showAction: 'false',
              contact_id: '',
              autoPaymentMethods:[],
              autoPaymentData:{},
            //  paymentMethods:[],
              availablePaymentMethods:[],
              notes: [],
              contactDrawer: false,
              // maintenance_id: '',
              // viewMaintenanceModal: false,
              fromLeaseWidget: true,
              showEditBreakdownModal: false,
              access: [],
              tenants: [],
              // maintenance: [],
              services: [],
              insurance: [],
              discount: [],
              promotionsData: {},
              selectedVehicle: {},
              vehicle_types: VEHICLE.TYPES,
              editServiceDialog: false,
              selectedService: {},
              todaysDate: '',
              is_loading: false,
              unit: [],
              leaseWithTenants: [],
              more_settings: [],
              invoice: {},
              invoices:[],
              ledger:[],
              taxRate: 0,
              offset: 0,
              showCharges: false,
              hovering: {
                  insurance: false,
                  services: false,
                  vehicle: false
              },
              current_row_hovered: '',
              openInvoices: false,
              pageCreated: false,
              payment_id: '',
              showPaymentView: false,
              showView: false,
              credits: [],
              bill_day: 1,
              prorate_invoice: [],
              prorate_days: 0,
              show_update_billing_modal: false,
              show_take_payment_modal: false,
              formErrors: '',
              disable_payment: false,
              writeOffModal: false,
              isSpaceAccessCodesVendor: false,
              dummyUnitData: { 
                "soft_catch": 1,
                "late_catch": 1,
                "hard_catch": 1,
                "pin": "12321432",
                "unit_id": "2342343",
                "unit_number": "234",
                "unit_type": "storage",
              },
              emittedType: '',
            }
        },
        components:{
            Modal,
            EditBreakdown,
            Status,
            CreateInvoiceModal,
            EditPayment,
            BillingOnTakePaymentModal,
            OverviewLeaseDetails,
            OverviewLeaseSettings,
            WriteOff,
            MiniProfileView
        },
        // BCT: Added prop 'parent' for conditional based changes in Space profile.
        props: ['lease', 'contact', 'payment_methods', 'parent','propertyView','elevation', 'unitData','nationalAccount'],
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                isSuperAdmin: 'authenticationStore/isSuperAdmin',
                hasPermission: "authenticationStore/rolePermission",
                checkPermissionProperties: 'authenticationStore/checkPermissionProperties'
            }),
            autopayDays() {
              let days = Array(31)
                .fill(0)
                .map((n, i) => i );
              return days;
            },
            is_closed(){
                return moment(this.lease.end_date).startOf('day') <= moment().startOf('day');
            },
            
            nonInsuranceServices(){
                return this.services.filter(s => s.Product && s.Product.default_type !== 'insurance' && s.Product.default_type !== 'rent');
            },
            refundPayments(){
                /* need logic to send eligible refundable payments to refund modal */
                return [];
            },
            auctionCleaningDeposit() {
                return (this.lease.LeaseAuction && this.lease.LeaseAuction.cleaning_deposit && !this.lease.LeaseAuction.refund_id) ? this.lease.LeaseAuction.cleaning_deposit : 0;
            },
            insuranceIsCurrent(){
              let verifyInsurance = this.insurance && this.insurance.start_date && moment(this.insurance.start_date).subtract(8, 'hours').startOf('day') <= moment().startOf('day')
              if(verifyInsurance) {
                return !this.insurance.end_date || (this.insurance.end_date && moment(this.insurance.end_date).subtract(8, 'hours').startOf('day') >= moment().startOf('day'))
              }
            },
            isAuctionPaymentOrMovedOut() {
              return this.leaseWithTenants && this.leaseWithTenants.auction_status === 'move_out';
            },
            computedContactTitle() {
              return (this.contact.first ? this.contact.first : '') + (this.contact.middle ? ' ' + this.contact.middle : '') + (this.contact.last ? ' ' + this.contact.last : '');
            },
            computedContactPhone() {
              if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
                return this.contact.Phones[0].phone;
              } else {
                return undefined;
              }
            },
            computedContactPhoneVerified() {
              if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
                  return true;
              } else {
                  return false;
              }
            },
            computedContactAddress() {
              if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
                const address_1 = this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '';
                const address_2 = this.contact.Addresses[0].Address.address2 ? this.contact.Addresses[0].Address.address2 : '';

                let address = address_1;
                if(address_2 != '') address += ' ' + address_2;
                
                address += ', ' + this.$options.filters.formatAddress(this.contact.Addresses[0].Address); 
                
                return address;
              } else {
                return undefined;
              }
            },
            computedPropertyInfo() {
              if(this.unit && this.unit.Address){
                return this.$options.filters.formatAddress(this.unit.Address, '-', true);
              } else {
                return undefined;
              }
            },
            computedAccessHasError(){
                if(this.isSpaceAccessCodesVendor && this.lease && ((this.lease.Unit && this.lease.Unit.late_catch === 1) || (this.lease.Unit && this.lease.Unit.hard_catch === 1))){
                  return true;
                } else {
                  return false;
                }
            },
            computedHeaderBackgroundColor(){
              if((this.lease.balance > 0 && this.is_closed) || this.emittedType && this.emittedType == 'error'){
                return 'hb-error-shade';
              } else if(this.emittedType && this.emittedType == 'caution'){
                return 'hb-caution-shade';
              } else if(this.emittedType && this.emittedType == 'success'){
                return 'hb-success-shade';
              } else if(this.emittedType && this.emittedType == 'guidance'){
                return 'hb-guidance-shade';
              } else {
                return '';
              }
            },
            computedRepresentativeName() {
                if (this.contact.Representative) {
                  return (this.contact?.Representative?.Contact?.first ? this.contact?.Representative?.Contact?.first : '') + (this.contact?.Representative?.Contact?.last ? ' ' + this.contact?.Representative?.Contact?.last : '');
                }
                return undefined;
            },
        },
        async created (){
            if( this.parent ==  'space-profile') {
              this.more_settings = [0];
            }

            if(this.propertyView){
              await this.checkIsSpaceAccessCodesVendor(this.propertyView);
            }

            this.showPaymentView = false;

            // this.is_loading = true;
            this.fetchLease();
            if(!this.unitData || !Object.entries(this.unitData).length){
              this.fetchUnit();
            }else{
              this.unit = this.unitData
            }
            // await this.fetchOpenInvoices();
            //await this.fetchOpenPayments();
            //await this.fetchLeasePaymentMethods();
            this.fetchLeaseAutoPaymentMethods();
            
            this.fetchInsurance();
            this.fetchLeasePromotions();
            // this.fetchMaintenance();
            this.fetchServices();
            this.getCurrentServices();
            this.getVehicleData();
            this.fetchCredits();

            EventBus.$on('contact_updated', this.fetchLease);
            EventBus.$on('insurance_updated', this.fetchInsurance);
            EventBus.$on('service_updated', this.fetchServices);
            // EventBus.$on('service_updated', this.getCurrentServices);
            // EventBus.$on('lease_edited', this.updateEditedLease);
            // EventBus.$on('autopay_load',this.fetchLeasePaymentMethods);
            // EventBus.$on('update_charges_widget', this.eventBusActions);
            EventBus.$on('lease_payment_saved', this.getCurrentServices);
            EventBus.$on('payment_saved', this.getCurrentServices);
            EventBus.$on('invoice_saved', this.getCurrentServices);
            EventBus.$on('payment_method_deleted', this.paymentMethodRemoved);
            EventBus.$on('activeEdit', this.setActiveEditName);
            EventBus.$on('saveData', this.save);
            EventBus.$on('promotion_updated', this.fetchLeasePromotions);
            // EventBus.$on('showPaymentDone', this.showPayment);
            EventBus.$on('refetch_credit_payments', this.fetchCredits);
            EventBus.$on('fetch_unit_gate_status',this.refetchUnitInfo);
            
            this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');

            if(this.lease.id && this.lease.bill_day) {
              this.bill_day = this.lease.bill_day;
            }
            // this.is_loading = false;

            // this.pageCreated = true;

        },
        destroyed(){
            EventBus.$off('contact_updated', this.fetchLease);
            EventBus.$off('insurance_updated', this.fetchInsurance);
            EventBus.$off('service_updated', this.fetchServices);
            // EventBus.$off('service_updated', this.getCurrentServices);
            // EventBus.$off('lease_edited', this.updateEditedLease);
            //EventBus.$off('autopay_load',this.fetchLeasePaymentMethods);
            // EventBus.$off('update_charges_widget', this.eventBusActions);
            EventBus.$off('lease_payment_saved', this.getCurrentServices);
            EventBus.$off('payment_saved', this.getCurrentServices);
            EventBus.$off('invoice_saved', this.getCurrentServices);
            EventBus.$off('payment_method_deleted', this.paymentMethodRemoved);
            EventBus.$off('activeEdit', this.setActiveEditName);
            EventBus.$off('saveData', this.save);
            EventBus.$off('promotion_updated', this.fetchLeasePromotions);
            // EventBus.$off('showPaymentDone', this.showPayment);
            EventBus.$off('refetch_credit_payments', this.fetchCredits);
            EventBus.$off('fetch_unit_gate_status',this.refetchUnitInfo);
        },
        filters:{

            getCCIcon(method){
                var icon = ( ['amex', 'visa', 'mastercard'].indexOf(method.card_type) >= 0 ) ? method.card_type: 'credit_card';
                return  '/img/cc_icons/64/'+ icon +'.png';
            },
        },
        methods: {
          ...mapActions({
            getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
          }),
          ...mapMutations({
            setPaymentSource: "paymentsStore/setPaymentSource",
            createLeaseStructure: "paymentsStore/createLeaseStructure",
            setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
            setLeaseProperty: 'paymentsStore/setLeaseProperty',
            pushInvoices: 'paymentsStore/pushInvoices',
            resetPayments: 'paymentsStore/resetPayments',
            setPreSelectedLeaseId: 'paymentsStore/setPreSelectedLeaseId',
          }),
          resetBillingOnField() {
            this.resetPayments();
            this.lease.bill_day = this.bill_day;
            this.show_take_payment_modal = false;
            this.activelyEditing = '';
            this.prorate_invoice = [];
            this.prorate_days = 0;
            EventBus.$emit('reload_tenant_profile_leases');
          },
          async billingOnPayment(skipPayment = false) {
            if (this.hasPermission('bill_day_change_skip_payment')){
              try {
                let bill_day = this.lease.bill_day;
                let data = { dryrun: false, new_bill_day: bill_day }
                let r = await api.post(this, `${api.LEASES}${this.lease.id}/change-bill-day`, data);
                let description = '';
                if(skipPayment) {
                  description = `The billing day has been updated to ${this.$options.filters.dayEnding(bill_day)} of the month and an invoice has been generated and sent to the tenant.`;
                } else {
                  description = `The billing day has been updated to ${this.$options.filters.dayEnding(bill_day)} of the month.`
                }

                this.show_update_billing_modal = false;
                this.showMessageNotification({type: 'success', description: description});
                EventBus.$emit('activeEdit', '');
                EventBus.$emit('reload_tenant_profile_leases');
                this.activelyEditing = '';
                this.bill_day = this.lease.bill_day;
              } catch (err){
                this.showMessageNotification({description: err});
              }
            }else{
              this.showMessageNotification({ type: 'error', description: 'You do not have the permission to skip payment' });
            }
          },
            showTakePaymentModal() {
              this.show_update_billing_modal = false;
              this.show_take_payment_modal = true;
            },
            refetchUnitInfo(){
              this.fetchUnit();
            },
            async fetchPaymentMethods(pm){
              console.log("pm", pm)
              EventBus.$emit('payment_methods_updated');
              await this.setToAutoPay(pm)
              this.fetchLeaseAutoPaymentMethods();
            },
            showPayment(id) {
              this.payment_id = id;
              this.showPaymentView = true;
            },
            // NOTE: This function needs improvement 
            async save(prorate_invoice) {
              if(prorate_invoice?.lease_id && prorate_invoice.lease_id != this.lease.id) return;

              try {
                if(this.is_auto_pay_after_billing_date) {
                  await api.patch(this, `${api.LEASES}/${this.lease.id}/update`, {
                    auto_pay_after_billing_date: this.lease.auto_pay_after_billing_date
                  });
                  this.activelyEditing = '';
                  this.showMessageNotification({ type: 'success', description: 'AutoPay run day setting has been successfully updated.' });
                } else {
                  if(prorate_invoice?.lease_id == this.lease.id) {
                    this.show_update_billing_modal = true;
                  } else {
                    if(this.bill_day == this.lease.bill_day) {
                      this.activelyEditing = '';
                      return;
                    }
                    await this.billingOnPayment();
                  }
                }
              } catch (err) {}
            },
            // not called
            // async deleteNote(n){
            //     await api.post(this, api.CONTACTS + n.contact_id + '/pinned-interactions/' + n.id);
            //     this.notes.splice(this.notes.findIndex(note => note.id === n.id), 1);
            // },
            goContact(tenant){
                this.$router.push('/contacts/' + tenant.contact_id);
                // EventBus.$emit('goContact');
            },
            // viewMaintenance(m){
            //     this.maintenance_id = m.id;
            //     this.viewMaintenanceModal = true;
            // },
            editLease(){
                this.$emit('editLease');
            },
            // fetchLeasePaymentMethods(){
            //   this.paymentMethods
            //     api.get(this, api.LEASES + this.lease.id + '/payment-methods' ).then(r => {
            //         this.paymentMethods = r.paymentMethods;
            //     });
            // },
            async fetchLeaseAutoPaymentMethods(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/payment-methods/autopay' )
                this.autoPaymentMethods = r.paymentMethods;       
                this.autoPaymentData = r         
            },
            async fetchUnit(){
              let r = await api.get(this, api.UNITS + this.lease.Unit.id);
              this.unit = r.unit;
            }, 
            async setToAutoPay(paymentMethod){
                let r = await api.put(this, api.LEASES + this.lease.id + '/payment-methods/' + paymentMethod.id + '/autopay');
                this.fetchLeaseAutoPaymentMethods();
            },

            async removeAutoPay(paymentMethod, lease){
                let r = await api.delete(this, api.LEASES + this.lease.id + '/payment-methods/autopay');
                await this.fetchLeaseAutoPaymentMethods();
            },
            async fetchInsurance(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/insurance');
                this.insurance = r.data.insurance_service;
            },
            async fetchLeasePromotions() {
              let r = await api.get(this, api.LEASES + this.lease.id + '/all-discounts');
              this.promotionsData = r;
            },
            async fetchServices(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/services');
                this.services = r.services;
                this.is_loading = false;
            },
            async getVehicleData() {
                let r = await api.get(this, api.LEASES + this.lease.id + '/vehicles');
                if(!r.vehicles.id) {
                    this.selectedVehicle = {};
                    return;
                }
                this.selectedVehicle = r.vehicles;
            },
            async fetchLease(){
                this.is_loading = false;
                let r = await api.get(this, api.LEASES + this.lease.id);
                this.leaseWithTenants = r.lease;
                this.featuresLoading = false
            },
            async fetchPayments() {
                let r = await api.get(this, api.LEASES + this.lease.id + '/credits');
                // this.payments = r.payments.filter(payment => 
                //     payment.credit_type === 'payment'
                // );
                this.credits = r.payments.filter(payment => 
                    payment.credit_type === 'credit'
                );
            },
            async fetchCredits(payload){
                if(payload){
                  let { lease_id } = payload;
                  if(lease_id && lease_id != this.lease.id) return;
                }
                await api.get(this, api.LEASES + this.lease.id + '/credits').then(r => {
                    this.credits = r.payments.filter(payment => 
                        payment.credit_type === 'credit'
                    );
                });
            },

            insuranceDialogClose(){
                this.fetchInsurance();
            },
            async reloadServices(){
                this.is_loading = true;
                this.selectedService = {};
                this.showAddService = false;
                this.showEditService = false;
                await this.getCurrentServices();
                this.is_loading = false;

            },
            async cancelInvoice(){
              console.log("Now removing parent invoice");
              EventBus.$emit('activeEdit', '');
              this.show_update_billing_modal = false;
            },
            async eventBusActions(){
                // let r = await this.fetchOpenInvoices();
                let s = await this.getCurrentServices();
                //let t = await this.fetchOpenPayments();
                // let u = await this.getTaxRate();
                // TODO This shouldnt be necessary
                this.$nextTick(() => {
                    this.$forceUpdate();
                })
            },
            async getTaxRate(){
                let r = await api.get(this, api.SETTINGS_BILLING )
                this.taxRate = r.settings.taxRate;
            },
            async getCurrentServices(){
                var params = {
                    offset: this.offset
                };
                let r = await api.get(this, api.LEASES + this.lease.id + '/current-charges',  params );
                this.invoice = r.invoice;
            },
            // async fetchOpenInvoices(){
            //     this.OpenInvoices = this.lease.OpenInvoices;
            //     // let r = await api.get(this, api.LEASES + this.lease.id + '/invoices/past-due', {}, 'overview-invoices')

            //     // this.openInvoices = (r.invoices && r.invoices.length) ? true : false;

            // },
            // async fetchOpenPayments(){
            //     let r = await api.get(this, api.LEASES + this.lease.id + '/ledger');
            //     this.ledger = r.ledger;
            // },
            async prevPeriod() {
                this.offset--;
                await this.getCurrentServices();
            },
            async nextPeriod(){
                this.offset++;
                await this.getCurrentServices();
            },
            goRefund(){
                this.$emit('takeAction', 'refund', this.refundPayments);
            },
            paymentMethodRemoved(id){
                if(this.autoPaymentMethods && this.autoPaymentMethods[0] && id && id === this.autoPaymentMethods[0].id){
                    this.autoPaymentMethods = [];
                }
            },
            setActiveEditName(name) {
              this.is_auto_pay_after_billing_date = (name == 'autopay') ?  true : false;
              this.activelyEditing = name;
              if(name == '') {
                this.resetPayments();
                this.lease.bill_day = this.bill_day;
                this.prorate_invoice = [];
              }
            },
            async checkIsSpaceAccessCodesVendor(property_id){
              if(property_id){
                  try {
                      let r = await api.get(this, api.PROPERTIES + property_id + '/access');
                      
                      if(r.property_access_name === 'Derrels'){
                          this.isSpaceAccessCodesVendor = true;
                      }
                  } catch (err){
                      console.log(err);
                  }
              }
            },
            viewContact(type){
              if(type === 'access'){
                  this.$router.push('/contacts/' + this.$route.params.contact_id + '/gate-access');
              } else if(type === 'payment-methods'){
                  this.$router.push('/contacts/' + this.$route.params.contact_id + '/payment_methods');
              }
            },
            goPayment(){

              this.setPreSelectedLeaseId(this.lease.id)

              EventBus.$emit('make_payment', {
                property_id: this.leaseWithTenants.Unit.property_id,
                contact_id: this.$route.params.contact_id
              });                
            },
            emitType(type){
              this.emittedType = type;
            }   
        },
        
        mixins: [ calendarMixin, notificationMixin ],
        watch: {
            lease(){
                //if(this.pageCreated){
                    //this.is_loading = true;
                    this.fetchLease();
                    // this.fetchOpenInvoices();
                    this.fetchUnit();
                    this.fetchLeaseAutoPaymentMethods();
                    this.fetchInsurance();
                    this.fetchLeasePromotions();
                    this.fetchServices();
                    this.getCurrentServices();
                    this.getVehicleData();
                    //this.is_loading = false;
                //}
            }
        }
    }
</script>
<style lang="scss" scoped>
  .ti-widget {
    .ti-widget__header {
      padding: 14px 0px 16px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .ti-widget__title {
        font-size: 1.2em;
        line-height: 1em;
        font-weight: bold;
        padding-top: 0px;
        padding-bottom: 5px;
      }
      .ti-widget__subtitle {
        opacity: 0.8;
        font-size: 0.9em;
        line-height: 1em;
        font-weight: normal;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
  .gray-color{
    color:#637381;
  }
  .lease-column-1{
    background:#F4F6F8;
  }
  .cc-icon {
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    width: 64px;
  }
  .card-type{
    text-transform: uppercase;
    font-weight: 500;
  }
  .col-cursor-pointer{
    cursor:pointer;
  }

  .property-link{
    color:#306FB6;
    text-decoration:none;
  }

  .row-title {
    font-weight: 500;
  }

  .secondary-button{
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border:1px solid #C4CDD5;
    box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  }

  .hb-lease-dropdown-header {
    min-height: 40px !important;
  }

  .accessRestrictionsWrapper {
    background-color: #F9FAFB;
  }

  .overview-lease-widget-card-wrapper {
    border: 1px solid #DFE3E8; 
  }

  button.v-expansion-panel-header.hb-link{
    background: #e0f5f5 !important;
  }
</style>
