<template>
  <div>
    <v-container class="payment-method-container">
      <v-row v-if="!dense_header">
        <v-col cols="6"><strong>Credit/Debit</strong></v-col>
        <!-- TODO: Move to new component -->
        <v-col cols="6" class="pb-0 pt-0 align-right">
          <v-list-item-avatar tile class="mt-0" style="height: 25px;">
              <v-img src="/img/payment_method_logos/visa-card.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar tile class="mt-0" style="height: 25px;">
              <v-img src="/img/payment_method_logos/Mastercard.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar tile class="mt-0 ml-4" style="height: 25px;">
              <v-img src="/img/payment_method_logos/american-express.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar tile class="mt-n1 ml-4">
              <v-img src="/img/payment_method_logos/Discover-Card.svg"></v-img>
          </v-list-item-avatar>
        </v-col>
      </v-row>
      <div v-show="cardMethods.length">
        <v-row dense class="mt-0">
          <v-col><strong>Payment Method</strong></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-radio-group dense v-model="method" :mandatory="false" @change="handleMethodChange">
              <v-radio value="existing">
                <template v-slot:label>
                  <v-select
                    style="font-size: 14px;"
                    :disabled="method !== 'existing'"
                    hide-details
                    :value="payment.payment_method_id"
                    :items="cardMethods"
                    item-value="id"
                    clearable
                    item-text="card_end"
                    :error-messages="errors.collect('existing_payment_method_id')"
                    placeholder="Card On File"
                    dense
                    @change="handleUpdate('payment', 'payment_method_id', $event)"
                    v-validate="method === 'existing'? 'required' : ''"
                    :class="{'custom-field-error' : errors.first('existing_payment_method_id')}"
                    name="existing_payment_method_id"
                    id="existing_payment_method_id"
                    data-vv-name="existing_payment_method_id"
                    data-vv-as="Card On File"
                  >
                    <template v-slot:selection="data" >
                      ****{{data.item.card_end}}
                    </template>

                    <template v-slot:item="data" >
                      <v-list-item-avatar tile>
                        <v-img v-show="data.item.card_type === 'visa'" src="/img/cc_icons/64/visa.png"></v-img>
                        <v-img v-show="data.item.card_type === 'mastercard'" src="/img/cc_icons/64/mastercard.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>{{data.item.name_on_card}} </strong>
                        </v-list-item-title>
                        <v-list-item-subtitle>****{{data.item.card_end}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </template>
              </v-radio>

              <v-radio value="new" @change="handleUpdate('paymentMethodID', 'null', $event)" >
                <template v-slot:label>
                  <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>New Credit/Debit Card
                </template>
              </v-radio>
            </v-radio-group>

          </v-col>
        </v-row>
      </div>

      <!-- <v-divider v-show="method === 'new' && !dense_header"></v-divider> -->

<!-- Charm Payments -->
<div v-if="method === 'new' && (inCall && IvrPaymentEnable)">
  <v-card   elevation="0" outlined :style="{ border: !charmTenant ? '1px solid #00848E' :  `1px solid #DFE3E8`}">
    <v-card-title class="custom-header-charm pt-2 " :style="{ backgroundColor: !charmTenant ? '#E0F5F5' :'#F9FAFB' , borderBottom: !charmTenant ? '1px solid #00848E' :  `1px solid #DFE3E8` }">

      <v-row justify="space-between" align="center" no-gutters>
        <v-col cols="auto">
          <span class="hb-font-body-medium">Billing Details</span>
        </v-col>
        <v-col cols="auto" v-if="!ivrPaymentSessionStarted">
          <v-checkbox class="pt-0" style="margin-top: 2px;"  hide-details v-model="charmTenant"  @change="charmSameAsTenant($event)" label="Same as Tenant"></v-checkbox>
        </v-col>
        <v-col cols="auto" v-else-if="ivrPaymentSessionStarted">

      <!-- edit confirmation -->
                <hb-modal 
                    title="Edit Billing Details"    
                    v-model="editBillingDialog" 
                    size="medium"
                    content-padding
                    confirmation
                    @close="editBillingDialog = false"
                >
                <template v-slot:activator>
                  <hb-btn color="secondary" small>Edit</hb-btn>
                </template>
                    <template v-slot:content>
                        <p class="hb-text-night hb-font-body-medium">Editing the Billing Details will delete the cached Card Information.</p>
                        <p class="hb-text-night hb-font-body-medium">The payment process will be restarted.</p>
                        <p class="hb-text-night hb-font-body">Are you sure you want to continue?</p>
                    </template>
                    <template v-slot:right-actions>
                      <hb-btn @click="CharmEditAddress()" color="primary">Edit</hb-btn>
                  </template>
                 
                </hb-modal>
      <!-- edit confirmation -->


        </v-col>
      </v-row>
    </v-card-title>






  <div v-if="charmTenant || ivrPaymentSessionStarted" class="pa-4">
   <span class="hb-font-caption-medium"> Cardholder Name </span>
    <p>{{ payment_method.name_on_card }}</p>
       
    <span class="hb-font-caption-medium"> Billing Address </span>
    <p>
      
      <!-- {{ payment_method.address }}
      {{ payment_method.address2 }}
      {{ payment_method.country }}
      {{ payment_method.state }} -->
      {{ payment_method.zip  }}
    </p>
  
  </div>
  <div v-else-if="!charmTenant" class="pl-4 pr-4 pb-4">
    <v-row dense no-gutters>
      <v-col>
        <v-text-field
          hide-details
          :class="{'custom-field-error' : errors.first('name_on_card')}"
          :value="payment_method.name_on_card"
          v-validate="'required|max:45'"
          data-vv-name="name_on_card"
          data-vv-as="Name On Card"
          :error-messages="errors.collect('name_on_card')"
          label="Name On Card*"
          id="card-name"
          name="card-name"
          autocomplete="cc-card-name"
          @input="handleInputUpdate('payment_method', 'name_on_card', $event)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" v-if="paymentConfig.SHOW_TENANT_NAME && contact && contact.first && (contact.last || isRentAsBusiness)">
        <hb-checkbox label="Same as Tenant" v-model="allowTenantName"
          hide-details class="float-right mt-5">
        </hb-checkbox>
      </v-col>
    </v-row>

    <v-row v-if="!dense_header" dense class="mt-3" >
      <v-col><span class="font-weight-medium">Billing Address</span>
        <v-checkbox label="Same as Tenant" v-model="allowDefaultAddress" 
          v-if="paymentConfig.SHOW_BILLING_ADDRESS && ten_address && ten_address.length > 0" hide-details class="float-right mt-0 pt-0">
        </v-checkbox></v-col>
    </v-row>
    <v-row v-else dense class="mt-3" no-gutters>
      <v-col cols="12" class="py-0">
        <span class="label-heading-1 font-weight-medium">Billing Address</span>
        <br>
        <v-checkbox label="Same as Tenant" v-model="allowDefaultAddress" v-if="ten_address && ten_address.length > 0" hide-details class="my-3 pt-0 hb-text-night"></v-checkbox>
      </v-col>
    </v-row>

    <!-- <v-row dense no-gutters>
      <v-col cols="12">
        <v-text-field
          :class="{'custom-field-error' : errors.first('address')}"
          ref="address"
          hide-details
          :value="payment_method.address"
          v-validate="'required|max:100'"
          data-vv-name="address"
          data-vv-as="Address"
          :error-messages="errors.collect('address')"
          label="Billing Address*"
          id="card-billing-address"
          name="card-billing-address"
          autocomplete="cc-card-billing-address"
          @input="handleInputUpdate('payment_method', 'address', $event)"
          >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          :class="{'custom-field-error' : errors.first('address2')}"
          hide-details
          :value="payment_method.address2"
          v-validate="'max:45'"
          data-vv-name="address2"
          data-vv-as="Billing Address"
          :error-messages="errors.collect('address2')"
          label="Apt, Suite, etc."
          id="card-billing-address2"
          name="card-billing-address2"
          autocomplete="cc-card-billing-address2"
          @input="handleInputUpdate('payment_method', 'address2', $event)"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" class="mt-3">
        <HbAutocomplete
            :class="{'custom-field-error' : errors.first('country')}"
            :items="countriesListMixin"
            hide-details
            v-validate="'required'"
            :value="payment_method.country"
            label="Country*"
            single-line
            id="country"
            data-vv-as="Country"
            name="country"
            :error-messages="errors.collect('country')"
            @change="handleUpdate('payment_method', 'country', $event)"
        >
        </HbAutocomplete>
      </v-col>
      <v-col cols="12" class="mt-3">
          <HbAutocomplete
           :class="{'custom-field-error' : errors.first('state')}"
           :items="paymentStateListMixin"
           hide-details
           item-text='name'
           item-value='value'
           :value="payment_method.state"
           label="State"
           single-line
           id="state"
           data-vv-as="State"
           name="state"
           :error-messages="errors.collect('state')"
           @change="handleUpdate('payment_method', 'state', $event)"
          />
      </v-col>
    </v-row> -->

    <v-row dense no-gutters justify="space-between" align="center">
      <!-- <v-col cols="7" class="mt-5">
        <HbAutocomplete
          :class="{'custom-field-error' : errors.first('city')}"
          hide-details
          v-validate="paymentCityListMixin.length ? 'required' : ''"
          :items="paymentCityListMixin"
          :value="payment_method.city"
          label="City*"
          single-line
          id="city"
          data-vv-as="City"
          name="city"
          :error-messages="errors.collect('city')"
          @change="handleUpdate('payment_method', 'city', $event)"
          @keydown.enter="setCityList($event.target.value, true)"
        >
        </HbAutocomplete>
      </v-col> -->
      <v-col cols="4">       
        <v-text-field
          :class="{'custom-field-error' : errors.first('zip')}"
          hide-details
          :value="payment_method.zip"
          v-validate="'required|max:10'"
          data-vv-name="zip"
          data-vv-as="Zip"
          :error-messages="errors.collect('zip')"
          label="Zip*"
          id="card-zip"
          name="card-zip"
          autocomplete="cc-card-zip"
          @input="handleInputUpdate('payment_method', 'zip', $event)"
        >
        </v-text-field>
      </v-col>

    </v-row>

  </div>
  </v-card>

  <v-card  class="mt-5"  elevation="0" outlined :style="{ border: `1px solid #DFE3E8` }">
    <v-card-title class="custom-header-charm pt-2 " :style="{ backgroundColor: '#F9FAFB' , borderBottom: `1px solid #DFE3E8` }">
      <span class="hb-font-body-medium">Payment Notes</span>
    </v-card-title> 
        <v-textarea
          :disabled="ivrPaymentSessionStarted"
          no-gutters
          class="pl-4 pr-4 pb-4 mt-n2"
          v-if="!dense_header"
          :class="{'custom-field-error' : errors.first('notes')}"
          rows="2"
          hide-details
          :value="payment.notes"
          data-vv-name="notes"
          :error-messages="errors.collect('notes')"
          id="card-notes"
          name="card-notes"
          autocomplete="cc-card-notes"
          @input="handleInputUpdate('payment', 'notes', $event)"
          auto-grow    
          >
        </v-textarea>
  </v-card>



  <div  class="d-flex pt-4" v-if="!dense_header && paymentConfig.SHOW_SAVE_PAYMENT">
    <v-checkbox  class="pt-0" style="margin-top: 2px;" :disabled="auto_charge || ivrPaymentSessionStarted" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method">
    </v-checkbox>  
  </div>
  <p  class="hb-text-light pl-8 hb-font-caption">
    *This option must be selected before collecting card information.
  </p>
 

  <charmIvr ref="charmIvr" class="mt-5" v-if="(inCall && IvrPaymentEnable)"  :paymentSource="paymentSource" :saveCard="saveCard" :property_id="property_id" :contact_id="contact_id">     
  </charmIvr> 
</div>
<!-- Charm Payments -->


      <div v-show="method === 'new' && !(inCall && IvrPaymentEnable)">
        <v-row v-if="!dense_header" dense class="mt-2 pb-3">
          <v-col cols="7" class="pt-2">
            <strong>Card Information</strong>
          </v-col>
          <v-col cols="5" class="pl-5" v-if="paymentConfig.SHOW_SAVE_PAYMENT">
            <v-checkbox class="mt-0 pt-0" :disabled="auto_charge" hide-details :input-value="payment_method.save_to_account" @change="updateSaveToAccount($event)" label="Save Payment Method"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="py-0">
            <strong class="label-heading-1">Card Information</strong>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field
              :class="{'custom-field-error' : errors.first('name_on_card')}"
              hide-details
              :value="payment_method.name_on_card"
              v-validate="'required|max:45'"
              data-vv-name="name_on_card"
              data-vv-as="Name On Card"
              :error-messages="errors.collect('name_on_card')"
              label="Name On Card*"
              id="card-name"
              name="card-name"
              autocomplete="cc-card-name"
              @input="handleInputUpdate('payment_method', 'name_on_card', $event)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" v-if="paymentConfig.SHOW_TENANT_NAME && contact && contact.first && (contact.last || isRentAsBusiness)">
            <hb-checkbox label="Same as Tenant Name" v-model="allowTenantName"
              hide-details class="float-right mt-5">
            </hb-checkbox>
          </v-col>
        </v-row>
        
        <v-row dense v-if="method === 'new' && !(inCall && IvrPaymentEnable)">
          <v-col cols="8">
            <card-number 

            :method="method" ref="hbCardNumber" />
          </v-col>  
            <v-col cols="4">
            <card-cvv

            :method="method" ref="hbCvvNumber" />
</v-col>
        </v-row>        

        <v-row dense v-if="!(inCall && IvrPaymentEnable)">
          <v-col cols="8">
            <v-select
              :class="{'custom-field-error' : errors.first('exp_month')}"
              :items="monthList"
              hide-details
              :value="payment_method.exp_mo"
              v-validate="'required'"
              data-vv-name="exp_month"
              data-vv-as="Expiration Month"
              :error-messages="errors.collect('exp_month')"
              label="Expiration Month*"
              @change="handleUpdate('payment_method', 'exp_mo', $event)"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :class="{'custom-field-error' : errors.first('exp_yr')}"
              :items="yearList"
              hide-details
              :value="payment_method.exp_yr"
              v-validate="'required'"
              data-vv-name="exp_yr"
              data-vv-as="Expiration Year"
              :error-messages="errors.collect('exp_yr')"
              label="Expiration Year*"
              @change="handleUpdate('payment_method', 'exp_yr', $event)"
              ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="!dense_header" dense class="mt-6">
          <v-col v-if="paymentConfig.SHOW_BILLING_ADDRESS">
            <strong>Billing Zip</strong>
            <!-- <v-checkbox label="Default Address" v-model="allowDefaultAddress" hide-details class="float-right mt-0 pt-0"></v-checkbox> -->
          </v-col>
        </v-row>
        <v-row v-else dense class="mt-5">
          <v-col cols="12" class="py-0">
            <strong class="label-heading-1">Billing Zip</strong>
            <br>
            <!-- <v-checkbox label="Use tenant's Address" v-model="allowDefaultAddress" v-if="ten_address && ten_address.length > 0" hide-details class="mt-3"></v-checkbox> -->
          </v-col>
        </v-row>

        <!--<v-row dense>
           <v-col cols="12">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address')}"
              ref="address"
              hide-details
              :value="payment_method.address"
              v-validate="'required|max:100'"
              data-vv-name="address"
              data-vv-as="Address"
              :error-messages="errors.collect('address')"
              label="Billing Address*"
              id="card-billing-address"
              name="card-billing-address"
              autocomplete="cc-card-billing-address"
              @input="handleInputUpdate('payment_method', 'address', $event)"
              >
            </v-text-field>
          </v-col> -->
           <!-- <v-col cols="12">
            <v-text-field
              :class="{'custom-field-error' : errors.first('address2')}"
              hide-details
              :value="payment_method.address2"
              v-validate="'max:45'"
              data-vv-name="address2"
              data-vv-as="Billing Address"
              :error-messages="errors.collect('address2')"
              label="Apt, Suite, Etc."
              id="card-billing-address2"
              name="card-billing-address2"
              autocomplete="cc-card-billing-address2"
              @input="handleInputUpdate('payment_method', 'address2', $event)"
            >
            </v-text-field>
          </v-col> -->
          <!-- <v-col cols="12" class="mt-3"> 
            <HbAutocomplete
                :class="{'custom-field-error' : errors.first('country')}"
                :items="countriesListMixin"
                hide-details
                v-validate="'required'"
                :value="payment_method.country"
                label="Country*"
                single-line
                id="country"
                data-vv-as="Country"
                name="country"
                :error-messages="errors.collect('country')"
                @change="handleUpdate('payment_method', 'country', $event)"
            >
            </HbAutocomplete>
          </v-col> -->
          <!-- <v-col cols="12"  class="mt-3">
            <HbAutocomplete
               :class="{'custom-field-error' : errors.first('state')}"
               :items="paymentStateListMixin"
               hide-details
               item-text='name'
               item-value='value'
               :value="payment_method.state"
               label="State"
               single-line
               data-vv-as="State"
               name="credit_state"
               id="credit_state"
               :error-messages="errors.collect('state')"
               @change="handleUpdate('payment_method', 'state', $event)"
              />
          </v-col>
        </v-row>  -->

        <v-row dense>
          <!-- <v-col cols="8"  class="mt-5">
              <HbAutocomplete
              :class="{'custom-field-error' : errors.first('city')}"
              hide-details
              :items="paymentCityListMixin"
              :value="payment_method.city"
              v-validate="paymentCityListMixin.length ? 'required' : ''"
              label="City*"
              single-line
              id="card-city"
              data-vv-as="City"
              name="card-city"
              :error-messages="errors.collect('city')"
              @change="handleUpdate('payment_method', 'city', $event)"
              @keydown.enter="setCityList($event.target.value, true)"
            >
            </HbAutocomplete>
          </v-col> -->
          <v-col cols="4">
            <v-text-field
              :class="{'custom-field-error' : errors.first('card-zip')}"
              hide-details
              :value="payment_method.zip"
              v-validate="'required|max:10'"
              data-vv-name="zip"
              data-vv-as="Zip"
              :error-messages="errors.collect('zip')"
              label="Zip*"
              id="card-zip"
              name="card-zip"
              autocomplete="cc-card-zip"
              @input="handleInputUpdate('payment_method', 'zip', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="!dense_header">
          <v-col>
            <v-textarea
              :class="{'custom-field-error' : errors.first('notes')}"
              rows="2"
              hide-details
              :value="payment.notes"
              data-vv-name="notes"
              :error-messages="errors.collect('notes')"
              label="Notes"
              id="card-notes"
              name="card-notes"
              autocomplete="cc-card-notes"
              @input="handleInputUpdate('payment', 'notes', $event)"
              >
            </v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-container>


  </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import PaymentMethodBase from './PaymentMethodBase.vue';
    import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
    import { mapGetters, mapMutations } from "vuex";
    import moment from 'moment';
    import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import charmIvr from './CharmIvr.vue';

    export default {
        mixins: [ countryStateCityMixin,notificationMixin ],
        data() {
            return {
              method: null,
              allowTenantName: false,
              allowDefaultAddress: false,
              monthList:[
                { text:'January', value:'01' },
                { text:'February', value:'02' },
                { text:'March', value:'03' },
                { text:'April', value:'04' },
                { text:'May', value:'05' },
                { text:'June', value:'06' },
                { text:'July', value:'07' },
                { text:'August', value:'08' },
                { text:'September', value:'09' },
                { text:'October', value:'10' },
                { text:'November', value:'11' },
                { text:'December', value:'12' },
              ],
            //charm
            charmTenant:true,
            editBillingDialog:false


            }
        },
        components:{
            HbDatePicker,
            VuetifyStatepicker,
            charmIvr
        },
        computed:{
          inCall() {
            if(this.notification.status === 'active'){
              this.charmSameAsTenant(true)
            }else{
              this.charmSameAsTenant(false)
            }
          return this.notification.status === 'active';
         },
            yearList(){
            let year = moment().format('YYYY');
            let years = [];
            for(let i = 0; i < 11; i++){
              years.push({
                text:  (+year + i).toString(),
                value: (+year + i).toString()
              })
            }
            return years;
          }, 
            ...mapGetters({
            ivrPaymentSessionStarted:'charmCallStore/ivrPaymentSessionStarted',
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            getErrors: 'paymentsStore/getErrors',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
            payment_method: 'paymentsStore/getPaymentMethod',
            payment_methods: 'paymentsStore/getContactPaymentMethods',
            notification: 'charmCallStore/notification',
            IvrPaymentEnable: 'charmCallStore/IvrPaymentEnable'
          }),
            cardMethods(){
              return this.payment_methods.filter(pm => pm.type === 'card');
            },
            isRentAsBusiness(){
              return this.contact?.rent_as_business ? true : false;
            } 
         },
        created(){
          this.setCountriesListEvent()
          if(this.payment_method.country){
            this.setPaymentStatesListEvent(this.payment_method.country)
            if(!this.payment_method.state){
              this.setPaymentCountryCitiesListEvent(this.payment_method.country)
            }else{
              this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
            }
            this.setCityList(this.payment_method.city)
          }
        },
        async mounted(){
            if(this.cardMethods.length){
                this.method = 'existing';
            } else {
                this.method = 'new';
            }
        },
        destroyed() {
        },
        props: ['auto_charge','dense_header','ten_address','property_id','contact_id','saveCard','paymentSource'],
        methods:{
          CharmEditAddress(){
            this.editBillingDialog = false
            this.$refs.charmIvr.cancelCardCollection()
          },
          charmSameAsTenant(val){
              this.allowTenantName = val
              this.allowDefaultAddress = val
              this.charmTenant = val
          },
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
            setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
            setPaymentMethodID: 'paymentsStore/setPaymentMethodID',
            updateIvrPaymentMethod:'charmCallStore/updateIvrPaymentMethod'
          }),
          focusIf(num, ref, evt){
            if(evt.target.value.length === num){
                this.$refs[ref].focus();
            }
          },
          setCityList(value, toValidate = false){
            if(!value) return
            const isSpecialCharacter = this.validateCityName(value)
            const cityValidation = this.errors.items.indexOf('city')
            if(toValidate){
                this.errors.items.splice(cityValidation);
                if(isSpecialCharacter){
                    return this.errors.add({
                                field: 'city',
                                msg: 'Please add a valid city name.'
                            }) 
              }
            }

            const isCityExists = this.paymentCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
            if(isCityExists) return

            this.paymentCityListMixin.push(value)
            const country = this.countriesListMixinRaw.find(item => item.name === this.payment_method.country || this.ten_address[0].Address.country);
            this.addMissingCity({
                country_code: country.isoCode,
                state_code: this.payment_method.state || this.ten_address[0].Address.state,
                city: value
            })
          },
          setAddress(){
            const countryValue = this.setCountryNameEvent(this.ten_address[0].Address.country)
            this.setPaymentStatesListEvent(countryValue)
            this.setPaymentCitiesListEvent(countryValue, this.ten_address[0].Address.state)
            this.setCityList(this.ten_address[0].Address.city)
            let updatedPM = {
              address: this.ten_address[0].Address.address,
              address2: this.ten_address[0].Address.address2,
              zip: this.ten_address[0].Address.zip,
              city: this.ten_address[0].Address.city,
              state: this.ten_address[0].Address.state,
              country: countryValue

            }
            this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
          },
          resetAddress(){
            let updatedPM = {
              address: '',
              address2: '',
              zip: '',
              city: '',
              state: '',
              country:''
            }
            this.setPaymentMethodObject({paymentMethod: {...this.payment_method, ...updatedPM}});
                      },
          handleUpdate(obj, property_name, property_value){
            if(obj === 'payment'){
              this.setPayment({property: property_name, propertyValue: property_value});
            }
            if(obj === 'paymentMethodID'){
                this.setPaymentMethodID('resetPaymentMethodID');
            }
            if(obj === 'payment_method'){
              this.setPaymentMethod({property: property_name, propertyValue: property_value});
              switch(true){
                  case property_name === 'country':
                    this.setPaymentMethod({property: 'state', propertyValue: ''});
                    this.setPaymentMethod({property: 'city', propertyValue: ''});
                    this.setPaymentStatesListEvent(this.payment_method.country)
                    this.setPaymentCountryCitiesListEvent(this.payment_method.country)
                    break;
                  case property_name === 'state':
                    this.setPaymentMethod({property: 'city', propertyValue: ''});
                    this.setPaymentCitiesListEvent(this.payment_method.country, this.payment_method.state)
                    break;
                  case property_name === 'city':
                    this.setPaymentMethod({property: property_name, propertyValue: property_value});
                    break;
              }
            }

          },
          handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
            this.handleUpdate(obj, property_name, property_value);
          }, 200),

          updateSaveToAccount(val){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: !!val}); 
          },
          handleMethodChange(){
            this.$validator.reset();
          },
          async validateFields(){
            if(this.method === 'existing'){
              return this.$validator.validate('existing_payment_method_id')
            }
            if(this.method === 'new'){
              const fieldsValidation = this.$validator.validateAll();
              const cardNumberValidation = this.$refs.hbCardNumber.validateCardNumber();
              const cardCVVValidation  = this.$refs.hbCvvNumber.validateCardCVV();
              const [fieldsValid, cardNumberValid, cardCVVValid] = await Promise.all([
                fieldsValidation,
                cardNumberValidation,
                cardCVVValidation
              ]);

              return fieldsValid && cardNumberValid && cardCVVValid;
            }
          }
        },
        watch:{    
          method(){
            this.updateIvrPaymentMethod(this.method)
          },
          auto_charge(){
            this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
          },
          allowDefaultAddress(val){
            if (val) {
              this.setAddress();
            } else {
              this.resetAddress();
            }
          },
          allowTenantName(val){
            if (val) {
              this.setPaymentMethod({property: 'name_on_card', propertyValue: `${this.contact.first} ${!this.isRentAsBusiness ? this.contact.last : ''}`});
            } else {
              this.setPaymentMethod({property: 'name_on_card', propertyValue: ''});
            }
          }
        }


    }
</script>
<style scoped>
  .payment-method-container{
    color: #101318;
  }
  .label-heading-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101318;
  }
  .custom-header-charm {
    padding-bottom: 8px;
  }
</style>
